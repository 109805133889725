import React from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import GetSelectOptionList from '../NewPayment/GetSelectOptionList';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

const CreateQuery = () => {
    const [file, setFile] = useState()
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [selectVal, setSelectVal] = useState("");
    const [selectFormVal, setSelectFormVal] = useState("");
    const [selectPayModeVal, setSelectPayModeVal] = useState("");
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    function handleChangeFile(event) {
        setFile(event.target.files[0])
        const name = event.target.name;
        const value = event.target.files[0];
        setInputs(values => ({ ...values, [name]: value }))
    }
    const urlSearchString = window.location.search;
    let trId;
    let summ;
    let Rdate;
    if (urlSearchString !== "") {
        const params = new URLSearchParams(urlSearchString);
        trId = params.get('Id');
        summ = params.get('summary');
        Rdate = params.get('date');
    }



    const handleSelectChange = (event) => {
        setSelectVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSelectFormChange = (event) => {
        setSelectFormVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handlePayModeChange = (event) => {
        setSelectPayModeVal(event.target.value)
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleDateFormatChange = (event) => {
        const fomDate = event.target.value;
        const fomDateloop = fomDate.split('-');
        const dd = fomDateloop[2];
        const mm = fomDateloop[1];
        const yy = fomDateloop[0];
        const formatOk = `${dd}/${mm}/${yy}`;
        const name = event.target.name;
        setInputs(values => ({ ...values, [name]: formatOk }))
    }


    function setBasicDistId(inputs) {
        inputs.distributorId = distID;
    }
    const handlePaymentSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(inputs).length !== 0) {
            setBasicDistId(inputs);
            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/Queries/AddNewQuery", inputs, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        navigate("/RaiseQuery");
                    }
                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        } else {

        }
    }
    const myTimeout = setTimeout(myLateCode, 1000);

    function myLateCode() {
        if (trId !== undefined) {
            const name = 'queryDetails';
            const value = trId;
            setInputs(values => ({ ...values, [name]: value }))
        }
        if (summ !== undefined) {
            const name = 'transactionId';
            const value = summ;
            setInputs(values => ({ ...values, [name]: value }))
        }
        if (Rdate !== undefined) {
            const name = 'date';
            const value = Rdate;
            setInputs(values => ({ ...values, [name]: value }))
        }
        myStopFunction();
    }

    function myStopFunction() {
        clearTimeout(myTimeout);
    }
    return (
        <>

            <section className="content">
                <div className="container-fluid">
                    <br />
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Raise Query</h3>
                                </div>
                                <div className="card-body nobornopad">
                                    <form className='master-form' onSubmit={handlePaymentSubmit} novalidate>
                                        <div className="row  card-body">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="Query By " className="form-label">Query By :</label>
                                                    <input type="text" name='QueryByName' className="form-control" placeholder=" Query By "
                                                        value={inputs.QueryByName} required
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>


                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="Transaction Id/Cheque# " className="form-label">Transaction Id/Cheque# :</label>
                                                    <input type="text" name='transactionId' className="form-control" placeholder="Transaction Id/Cheque ..."
                                                        value={inputs.transactionId}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="Date and time: " className="form-label">Transaction/Deposite Date :</label>
                                                    <input className="form-control" type="date" name='date'
                                                        onChange={handleDateFormatChange} pattern="^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$" />
                                                </div>
                                            </div>


                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="Details " className="form-label">Details</label>
                                                    <textarea name='queryDetails' className="form-control txtDetailsHt" placeholder="Enter Details here ..."
                                                        value={inputs.queryDetails} onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <input type="submit" className="btn btn-success float-right submit-btn" value=" Raise Query" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )



}


export default CreateQuery


