
import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useState } from 'react';

const WorkGuidence = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [inputs, setInputs] = useState({});
    const { data, error, isLoaded } = GetRequest(`/Farmer/GetFarmerlist/${distID}`);

    if (error !== null) {
        return <Error data=" Add Farmer From Your Area" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleChangeSelect = (event) => {
        const name = event.target.name;
        const value = JSON.parse(event.target.value);
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmitFarmerRecord = (event) => {
        event.preventDefault();

        axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/Farmer/AddFarmer", inputs, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-gray">
                                    <h3 className="card-title">Add Farmer From Your Area</h3>
                                </div>

                                <div className="card-body nobornopad">
                                    <form onSubmit={handleSubmitFarmerRecord} className='workguidenceevents master-form' noValidate >
                                        <div className="row">

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="name By" className="form-label">Name :</label>
                                                    <input type="text" name='name' className="form-control" placeholder="Enter Name"
                                                        value={inputs.name} required
                                                        onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="mobileNumber By" className="form-label">Number :</label>
                                                    <input type="text" name='mobileNumber' className="form-control" placeholder="Enter Number"
                                                        value={inputs.mobileNumber} required
                                                        onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="whatsAppNumber By" className="form-label">WhatsApp Number :</label>
                                                    <input type="text" name='whatsAppNumber' className="form-control" placeholder="Enter WhatsApp Number"
                                                        value={inputs.whatsAppNumber} required
                                                        onChange={handleChange} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="isInfluencerOrProgressiveFarmer By" className="form-label">Inflencer/Progressive Farmer :</label>
                                                    <select className="form-control" name='isInfluencerOrProgressiveFarmer' value={inputs.isInfluencerOrProgressiveFarmer} onChange={handleChangeSelect}>
                                                        <option value="">Select Inflencer/Progressive Farmer Or Not </option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="hasFarmerJoinedFarmerAppPlatform By" className="form-label">On Farmer App Platform? :</label>
                                                    <select className="form-control" name='hasFarmerJoinedFarmerAppPlatform' value={inputs.hasFarmerJoinedFarmerAppPlatform} onChange={handleChangeSelect}>
                                                        <option value="">Select Farmer App Joined Or Not </option>
                                                        <option value={true}>Yes</option>
                                                        <option value={false}>No</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="locationDetails" className="form-label">Location :</label>
                                                    <input type="text" className="form-control" name='locationDetails' placeholder="Enter Location"
                                                        value={inputs.locationDetails} onChange={handleChange} required />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label for="Other details" className="form-label">Other Details :</label>
                                                    <input type="text" className="form-control" name='otherDetails' placeholder="Enter Other Details"
                                                        value={inputs.otherDetails} onChange={handleChange} />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="col-sm-4"  >
                                                <br />
                                                <input type="submit" className="btn btn-success float-right submit-btn" value="Save" />
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div>

                    {/* <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header bg-gray">
                                    <h3 className="card-title">Import Farmers Data from Your area (You Can upload bill/list of number/ or excel/csv)</h3>
                                </div>

                                <div className="card-body nobornopad">
                                    <form className='workguidenceeventsRecipt master-form'>


                                        <div className="row card-body" >

                                            <div className="col-sm-6">
                                                <label for="Details " className="form-label">Upload Farmers Data CSV/Excel </label>
                                                <div className="file-drop-area">
                                                    <span className="choose-file-button">Upload Farmers Data CSV/Excel</span>
                                                    <span className="file-message">or drag and drop files here</span>
                                                    <input className="file-input" type="file" multiple />
                                                </div>
                                            </div>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                    </div> */}

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Farmers Added By You
                                    </h3>
                                </div>

                                <div className="card-body p-0 myMobViewTable">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                {/* <th>Select</th> */}
                                                <th>Name</th>
                                                <th>Number</th>
                                                <th>WhatsApp Number</th>
                                                <th>Inflencer/Progressive Farmer</th>
                                                {/* <th>On Farmer App Platform ?</th> */}
                                                <th>Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                data.result && data.result.map((items, index) => (
                                                    <tr>
                                                        {/* <td>
                                                            <div className="form-check">
                                                                <input type="checkbox" className="form-check-input" id={items.id}  />
                                                            </div>
                                                        </td> */}
                                                        <td><a href> {items.name} </a></td>
                                                        <td>{items.mobileNumber}</td>
                                                        <td>{items.whatsAppNumber}</td>
                                                        <td>{items.isInfluencerOrProgressiveFarmer ? 'Yes' : 'No'}</td>
                                                        {/* <td>{items.hasFarmerJoinedFarmerAppPlatform  ? 'Yes' : 'No'}</td> */}
                                                        <td>{items.locationDetails}</td>
                                                    </tr>
                                                ))
                                            }

                                        </tbody>

                                    </table>
                                </div>
                                {/* <div className="col-md-2"  >
                                    <a href className="btn text-success" data-bs-toggle="modal" data-bs-target="#divSendMessage">
                                        <button type="button" className="btn btn-success btn-block"><i className="fa fa-back"></i>Send Message</button>
                                    </a>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="modal" tabindex="-1" role="dialog" id="divSendMessage">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Message To Farmer</h5>

                                </div>
                                <div className="modal-body">

                                    <div className="input-group">

                                        <textarea className="form-control" rows="3" placeholder="Enter Message..."></textarea>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Send</button>
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WorkGuidence