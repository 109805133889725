import React from 'react'

import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, useParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";

import axios from 'axios';
import { useState } from 'react';


const BPUOrderList = () => {
  const Id = useParams();
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const bpuID = localStorage.getItem('userId');
  const bpuType = localStorage.getItem('userType');
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();


  const { data, error, isLoaded } = GetRequest(`/Orders/GetRecentOrders/${Id.Id}/${bpuType}/${bpuID}`);

  if (error !== null) {
    return <Error data="My Distributor Orders " />;
  }
  if (!isLoaded) {
    return <Loading />;
  }


  const handleEditOrder = (event) => {
    event.preventDefault();
    const editOrd = event.target.id;

    localStorage.setItem('distributorId', Id.Id);
    const fData = {};
    fData.orderLineId = editOrd;
    axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/Orders/EditOrderLine", fData, {
      headers: {
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*",
        'Authorization': localStorage.getItem("loginToken"),
      },
    })
      .then((response) => {
        if (response.data.isSuccess === true) {

          localStorage.setItem('ordereditOnOrderSummary', true);
          navigate("/NewOrder/OrderSummary");
        } else {
          document.getElementById(`showerror${editOrd}`).classList.remove('hide')
          setErrorMsg(response.data.message)
          setTimeout(function () {
            document.getElementById(`showerror${editOrd}`).classList.add('hide')
          }, 5000);
        }
      }).catch((response) => {
        console.log(response.statusText);
      });
  }



  return (
    <>
      <section className="content">
        <div className="container-fluid">
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">  Orders </h3>
            </div>
            <div className="card-body no-padding row">
              {
                data.result && data.result.map((item, index) => (

                  <div className="col-md-4">
                    <div className="card card-primary">
                      <div className="card-header ">
                        <h5 className="card-title">   {item.orderNumber} </h5>
                      </div>
                      <div className="card-body">
                        <p>Status : <b>{item.statusDisplayText} </b></p>
                        <p>Number Of Items : {item.numberOfItems}</p>
                        <p>Order Date : {item.orderPlacedDate}</p>
                        {/* <p>Amount  : <b>{item.totalWithTax}</b></p> */}
                        {item.orderApprovedBy != null ? <p>   Approved by: <b>{item.orderApprovedBy}</b>  </p> : ''}

                        {item.isOrderEditable == true ? <button className='btn btn-success btn-sm editMO' onClick={handleEditOrder} id={item.id}>Edit </button> : ''}
                        <Link to={`/BPUDashboard/BPUOrderDetails/${item.id}`} className='btn btn-primary btn-sm' id={item.orderNumber}>View Details</Link>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BPUOrderList