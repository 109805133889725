import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { useState } from 'react';

const WorkGuidenceEvents = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [inputs, setInputs] = useState({});
    const [file, setFile] = useState()
    const { data, error, isLoaded } = GetRequest(`/WC/GetWCListSharedByDistributor/${distID}`);

    if (error !== null) {
        return <Error data="Add Activity From Your Area " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    function setBasicDistId(inputs) {
        inputs.distributorId = distID;
    }
    function handleChangeFile(event) {
        setFile(event.target.files[0])
        const name = event.target.name;
        const value = event.target.files[0];
        setInputs(values => ({ ...values, [name]: value }))
    }
    const handleSubmitFarmerRecord = (event) => {
        event.preventDefault();
        setBasicDistId(inputs);
        axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/WC/AddWCItemFromDistributor", inputs, {
            headers: {
                "content-type": "multipart/form-data",
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }


    return (
        <>
            <section className="content">
                <div className="container-fluid">

                    <div className="col-12">
                        <div className="card">
                            <div className="card-header bg-gray">
                                <h3 className="card-title">Add Activity From Your Area </h3>
                            </div>
                            <div className="card-body nobornopad">
                                <form className='workguidenceevents master-form' onSubmit={handleSubmitFarmerRecord}>
                                    <div className="row  card-body">
                                        <div className='col-md-12'>
                                            <p className=''>
                                                <ul>
                                                    <li>You Can Request our attention on specific potential village to be covered.</li>
                                                    <li>Suggest Event to be planned</li>
                                                    <li>Provide details about event planned. Request Presence of Booster Paris.</li>
                                                    <li>Any Other Information which may help you to grow business with assistance from Booster Paris Team.</li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="Title " className="form-label">Title :</label>
                                                <input type="text" name='Title' className="form-control" placeholder="Enter title"
                                                    value={inputs.Title}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="AboutAreaVillage " className="form-label">About Area / Village :</label>
                                                <input type="text" name='AboutAreaVillage' className="form-control"
                                                    placeholder="Enter Area / Village"
                                                    value={inputs.AboutAreaVillage}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row card-body" >
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="ConcernedContactPerson  " className="form-label">Concerned Person/Contact Information :</label>
                                                <input type="text" name='ConcernedContactPerson ' className="form-control" placeholder="Contact Information ..."
                                                    value={inputs.ConcernedContactPerson}
                                                    onChange={handleChange} required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <label for="OtherDetails  " className="form-label">Other Details :</label>
                                                <textarea name='OtherDetails ' className="form-control" placeholder="Details ..."
                                                    value={inputs.OtherDetails}
                                                    onChange={handleChange} ></textarea>
                                                {/* <input type="text" name='OtherDetails ' className="form-control" placeholder="Details ..."
                                               value={inputs.OtherDetails}
                                               onChange={handleChange}    
                                        /> */}
                                            </div>
                                        </div>

                                    </div>
                                    {/* <div className="row card-body" > 
                                 <div className="col-sm-8">
                                     <label for="InformationFilePath  " className="form-label">Upload Event Invitation / Brouchure </label>
                                     <div className="file-drop-area">
                                         <span className="choose-file-button">Upload Event Invitation / Brouchure</span>
                                         <span className="file-message">or drag and drop files here</span>
                                         <input className="file-input" type="file" multiple name="InformationFilePath" onChange={handleChangeFile} />
                                     </div> 
                                 </div>
                             </div> */}
                                    <div className="row ">
                                        <div className="col-sm-4">
                                            <br /> <br />
                                            <input type="submit" className="btn btn-success float-right submit-btn" value="Save" />

                                        </div>
                                        <span id="clickSubmitError" className='hide'>
                                            <div className="alert alert-danger alert-dismissible">
                                                <button type="button" className="btn-close"  ></button>
                                                <strong>Info!</strong>  “Something went wrong, Please contact administrator.”
                                            </div>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/*  <div className="col-12" >
                <div className="card">
                    <div className="card-header bg-gray">
                        <h3 className="card-title">Upload Event Invitation / Brouchure </h3>
                    </div>

                    <div className="card-body nobornopad">
                        <form   className='workguidenceeventsRecipt master-form'> 
                           
                                 
                            <div className="row card-body" >
                                 
                                <div className="col-sm-6">
                                    <label for="InformationFilePath  " className="form-label">Upload Event Invitation / Brouchure </label>
                                    <div className="file-drop-area">
                                        <span className="choose-file-button">Upload Event Invitation / Brouchure</span>
                                        <span className="file-message">or drag and drop files here</span>
                                        <input className="file-input" type="file" multiple />
                                    </div> 
                                </div>
                            </div>
                             
                        </form> 

                    </div>
                </div>
            </div> */}


                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header bg-gray">
                                    <h3 className="card-title">
                                        Details Added By You
                                    </h3>
                                </div>

                                <div className="card-body p-0  myMobViewTable">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Title</th>

                                                <th>Village Name</th>
                                                {/* <th>Contact Information</th> */}
                                                {/* <th>Details</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.result && data.result.map((items, index) => (
                                                    <tr>
                                                        <td><a href> {items.title} </a></td>
                                                        <td>{items.aboutAreaVillage}</td>
                                                        {/* <td>{items.locationDetails}</td>
                                    <td>{items.whatsAppNumber}</td>  */}

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WorkGuidenceEvents