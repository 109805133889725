import React from "react";
import axios from 'axios';
import { useState } from 'react';
import demoImgCrop from '../Assets/img/cropDemo.jpeg'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link, NavLink, Outlet, json } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';



const NewOrderDashboard = () => {

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');

    const [addPrd, setAddPrd] = useState("");
    const [PID, setPID] = useState("0");
    const [actCardId, setActCartId] = useState('0');

    const { data, error, isLoaded } = GetRequest(`/Product/GetProductDetailsForOrderScreenForDistributor/${distID}`);
    // const { data, error, isLoaded } = GetRequest("/Product/GetProductDetailsForOrderScreen"); 
    const [qtyValue, setQtyValue] = useState();
    var prdatastate = {};
    const [productFormData, setProductFormData] = useState(prdatastate);

    setTimeout(function () {
        const checkUserType = localStorage.getItem('userType');
        if (checkUserType == 'BPUser') {
            document.getElementById('distributorNavList').classList.add('hide');
            document.getElementById('bpUserNavList').classList.remove('hide');
        }
    }, 1000);

    if (error !== null) {
        return <Error data=" New Order  " />;
    }
    if (!isLoaded) {
        return <Loading />;
    } else {
        addFilter();

    }

    checkOrderEdit();

    function checkOrderEdit() {
        const chkorEdit = localStorage.getItem('ordereditOnOrderSummary')
        if (chkorEdit == true) {
            document.getElementById('existOrderEdit').classList.add('active');
        }
        localStorage.setItem('ordereditOnOrderSummary', false);
    }
    const tostDismissAll = () => toast.clearWaitingQueue();

    const notifySuccess = () => toast.success('Product added in your Order Cart Summary!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifyErrorItem = () => toast.error('Cart Item count removed successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const setBtnPID = (event) => {
        const PsetID = event.target.id;
        setPID(PsetID);
        document.getElementById('qtyControl').value = "";
    }

    const ocsData = {};
    ocsData.orderCartLineItemDTOList = [];

    const handleSubmit = (event) => {
        event.preventDefault();
        const fData = {};
        fData.distributorId = distID;
        fData.productItemId = event.target.elements[1].id;
        fData.quantity = event.target.elements[0].value;

        axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/AddProductToCart", fData, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    setQtyValue('');
                    notifySuccess();

                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }

    function addFilter() {
        setTimeout(function () {
            let list = document.querySelectorAll('.listfilterhead');
            let itemBox = document.querySelectorAll('.itemfiltered');

            for (let i = 0; i < list.length; i++) {
                list[i].addEventListener('click', function () {
                    for (let j = 0; j < list.length; j++) {
                        list[j].classList.remove('active');

                    }
                    this.classList.add('active');

                    let dataFilter = this.getAttribute('data-filter');

                    for (let k = 0; k < itemBox.length; k++) {
                        itemBox[k].classList.remove('active');
                        itemBox[k].classList.add('hide');

                        if (itemBox[k].getAttribute('data-item') == dataFilter || dataFilter == "all") {
                            itemBox[k].classList.remove('hide');
                            itemBox[k].classList.add('active');
                        }
                    }

                })

            }
            loadCartDataValue();
        }, 1000);
    }

    const plusItemProductCount = (event) => {
        let currVal = event.currentTarget.previousSibling.value;
        if (currVal !== '') {
            currVal = parseInt(currVal) + 1;
            event.currentTarget.previousSibling.value = currVal;
            const formData = {};
            formData.distributorId = distID;
            formData.orderCartId = parseInt(document.getElementById("orderCartIdChangeQty").value);
            formData.orderCartLineItemId = 0;
            formData.productItemId = event.currentTarget.id
            formData.quantity = currVal;
            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/ChangeQuantityOfProductInCart", formData, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.data.isSuccess === true) {
                        tostDismissAll();
                        notifySuccess();
                    }

                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        } else {
            event.currentTarget.previousSibling.value = 1;
            const fData = {};
            fData.distributorId = distID;
            fData.productItemId = event.currentTarget.id;
            fData.quantity = 1;

            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/AddProductToCart", fData, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        tostDismissAll();
                        notifySuccess();
                        getActiveCardId();
                    }
                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        }
    }

    const addUpdateCartItem = (event) => {
        const valid = event.target.value;
        if (addPrd == 1 && valid !== '') {
            const fData = {};
            fData.distributorId = distID;
            // fData.productItemId = event.target.id;
            fData.productItemId = event.target.getAttribute('data-state-id');
            fData.quantity = event.target.value;

            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/AddProductToCart", fData, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        setAddPrd('');
                        tostDismissAll();
                        notifySuccess();
                        getActiveCardId();
                    }
                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        } else {
            if (event.target.value !== '') {
                const formData = {};
                formData.distributorId = distID;
                formData.orderCartId = document.getElementById("orderCartIdChangeQty").value;
                formData.orderCartLineItemId = 0;
                //formData.productItemId = event.currentTarget.id
                formData.productItemId = event.target.getAttribute('data-state-id');
                formData.quantity = parseInt(event.target.value);
                axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/ChangeQuantityOfProductInCart", formData, {
                    headers: {
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        'Authorization': localStorage.getItem("loginToken"),
                    },
                })
                    .then((response) => {
                        if (response.data.isSuccess === true) {
                            tostDismissAll();
                            notifySuccess();
                        }

                    }).catch((response) => {
                        // if(response.response.status === 401){  
                        //     window.location.replace("/Logout");
                        //  }
                        console.log(response.statusText);
                    });
            }
        }
    }
    const checkItemAddOrUpdate = (event) => {
        const checkAddUp = event.target.value;
        if (checkAddUp == '') {
            setAddPrd(1);
        }
    }

    const minusItemProductCount = (event) => {
        let currValMin = event.currentTarget.nextSibling.value;
        if (currValMin !== '' && parseInt(currValMin) !== 0) {
            if (parseInt(currValMin) !== 0) {
                currValMin = parseInt(currValMin) - 1;
                event.currentTarget.nextSibling.value = currValMin;
                const formData = {};
                formData.distributorId = distID;
                formData.orderCartId = document.getElementById("orderCartIdChangeQty").value;
                formData.orderCartLineItemId = 0;
                formData.productItemId = event.currentTarget.id
                formData.quantity = currValMin;
                axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/ChangeQuantityOfProductInCart", formData, {
                    headers: {
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        'Authorization': localStorage.getItem("loginToken"),
                    },
                })
                    .then((response) => {
                        if (response.data.isSuccess === true) {
                            tostDismissAll();
                            notifyErrorItem();
                        }

                    }).catch((response) => {
                        // if(response.response.status === 401){  
                        //     window.location.replace("/Logout");
                        //  }
                        console.log(response.statusText);
                    });
            }
        } else {

            event.currentTarget.nextSibling.value = 0;
            if (parseInt(currValMin) !== 0) {
                const formData = {};
                formData.distributorId = distID;
                formData.orderCartId = document.getElementById("orderCartIdChangeQty").value;
                formData.orderCartLineItemId = 0;
                formData.productItemId = event.currentTarget.id
                formData.quantity = currValMin;
                axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/ChangeQuantityOfProductInCart", formData, {
                    headers: {
                        "Cache-Control": "no-cache",
                        "Access-Control-Allow-Origin": "*",
                        'Authorization': localStorage.getItem("loginToken"),
                    },
                })
                    .then((response) => {
                        if (response.data.isSuccess === true) {
                            tostDismissAll();
                            notifyErrorItem();
                        }
                    }).catch((response) => {
                        // if(response.response.status === 401){  
                        //     window.location.replace("/Logout");
                        //  }
                        console.log(response.statusText);
                    });
            }
        }

    }

    function getActiveCardId() {
        if (actCardId == '') {
            axios.get(`https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/GetActiveOrderCart/${distID}`, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        setActCartId(response.data.result.orderCartId);
                    }
                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });

        }
    }

    setTimeout(function () {
        loadCartDataValue();
    }, 2000);

    function loadCartDataValue() {
        if (document.getElementById('searchProductFilter') !== null) {
            document.getElementById("searchProductFilter").value = '';
            setTimeout(function () {
                textFilterProducts();
            }, 500);

        }

        axios.get(`https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/GetActiveOrderCart/${distID}`, {
            headers: {
                "content-type": "multipart/form-data",
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.status === 200) {
                    cartDataUpdateLocal(response.data.result);
                }
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }

    function cartDataUpdateLocal(dataVal) {
        const myData = dataVal;
        if (dataVal.orderCartLineItemDTOList !== null) {
            localStorage.setItem('OredrCartProductData', JSON.stringify(myData));
            addCartDataInput();
        }
    }
    function addCartDataInput() {
        let crtData = JSON.parse(localStorage.getItem('OredrCartProductData'));
        if (crtData.hasOwnProperty('orderCartLineItemDTOList')) {
            if (crtData.orderCartLineItemDTOList.length !== null) {
                for (let i = 0; i <= crtData.orderCartLineItemDTOList.length; i++) {
                    let tval = Math.trunc(crtData.orderCartLineItemDTOList[i].qty);
                    document.getElementById(`crtval_${crtData.orderCartLineItemDTOList[i].productItemId}`).value = tval;
                }
            }
        }
    }

    function textFilterProducts() {
        try {
            var input, filter, ul, li, a, i, txtValue;
            if (document.getElementById("searchProductFilter") !== null) {
                input = document.getElementById("searchProductFilter");
                filter = input.value.toUpperCase();
                ul = document.getElementById("accordion");
                //  alert('test');
                li = ul.getElementsByTagName("label");
                //    alert('test22');
                for (i = 0; i < li.length; i++) {
                    a = li[i];
                    txtValue = a.textContent || a.innerText;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        li[i].parentElement.parentElement.style.display = "";
                    } else {
                        li[i].parentElement.parentElement.style.display = "none";
                    }
                }
            }

        }
        catch (e) {
            //alert(e);
        }
    }
    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card noUI productListNewOrd">
                                <div className="card-header headerNewOrderFlt">
                                    <NavLink to="/NewOrder" activeclassname="active" className='' onClick={loadCartDataValue}>
                                        <button className="form-control flBtn listfilterhead active" data-filter="all"> All </button>
                                        {
                                            data.result.map((item, index) => (
                                                <>
                                                    <button className="form-control flBtn listfilterhead" data-filter={item.id} id={item.id}> {item.name}  </button>
                                                </>
                                            ))
                                        }
                                    </NavLink>
                                    <Link to="/NewOrder/OrderSummary" id="existOrderEdit" className="form-control flBtn listfilterhead">Order Cart Summary</Link>
                                    <div className="dropdown NOfilter">
                                        <a href type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                            <i className="fa fa-search prdSear"></i>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <input className="form-control" id="searchProductFilter" onKeyUp={textFilterProducts} type="text" placeholder="Search Product.." />

                                            {/* {
                                                data.result.map((item, index) => (
                                                    <>
                                                        <li><a className="dropdown-item listfilterhead" href data-filter={item.id} id={item.id}> {item.name}  </a></li>
                                                    </>
                                                ))
                                            } */}

                                        </ul>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div id="accordion">
                                        {
                                            data.result && data.result.map((itemDt, index) => (
                                                <>
                                                    {
                                                        itemDt.productLines && itemDt.productLines.map((items, index) => (
                                                            <div className="card itemfiltered" data-item={itemDt.id} id={itemDt.id}>

                                                                <div className="card-header" data-bs-toggle="collapse" href={`#collapse${items.id}`}>

                                                                    {/* {items.productSmallImage == null ? <i className="fa fa-file-image-o dumProdIcon"></i> : <img className="ProductsHeadImgAcc" src={items.productSmallImage} alt="Crop image" width="100" />} */}
                                                                    <label className="pHeadName">{items.name}</label>

                                                                    <a className="btn dp-right-mt5" >

                                                                        <i className="fa fa-angle-double-down"></i>
                                                                        <i className="fa fa-angle-double-up"></i>
                                                                    </a>
                                                                </div>
                                                                <div id={`collapse${items.id}`} className="collapse" data-bs-parent="#accordion">
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-sm-5 hide">
                                                                                <table className="table nptb0">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            {/* <td style={{ valign: "top" }}> 
                                                                                             {items.productBigImage == null ? <i className="fa fa-file-image-o dumProdBigIcon"></i> : <img className="ProductsDetailsImgAcc" src={items.productBigImage} alt="Crop image" width="100" />}  
                                                                                             
                                                                                              </td> */}
                                                                                            {/* <td style={{ valign: "top" }}>    <p><h6>Technical Name : </h6> {items.technicalName}</p>
                                                                                                <p><h6>Dosage  : </h6> {items.dosageInformation}</p>
                                                                                            </td> */}

                                                                                        </tr>
                                                                                    </thead>
                                                                                </table>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                {
                                                                                    items.productItems && items.productItems.map((itd, index) => (
                                                                                        <>
                                                                                            <div className="row">
                                                                                                {/* <div className="row headTT1">
                                                                                                    <div className="col-12">
                                                                                                        <b> </b>{itd.name}
                                                                                                        <a className="btn dp-right-mt5" >                                                                                                        
                                                                                                        </a>
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                {/* <div className="row headTT">
                                                                                                    
                                                                                                    <div className="col-3"> Pack Name  </div>
                                                                                                    <div className="col-3"> Pack Size  </div>
                                                                                                    <div className="col-2"> Units  </div>
                                                                                                    <div className="col-4"> Quantity </div>
                                                                                                </div> */}
                                                                                                <div className="row headTT">
                                                                                                    {/* <div className="col-3"> {itd.transportRateTypeQty} {itd.primaryUnit} </div> */}

                                                                                                    <div className="col-6">  <b>  {itd.name} ({itd.packagingSizeDisplayName}) </b> </div>
                                                                                                    {/* <div className="col-2"> {itd.unitPackSize}  </div>
                                                                                                    <div className="col-2"> {itd.numberOfUnits}  </div> */}
                                                                                                    <div className="col-6">
                                                                                                        <div className="d-flex flex-row headTTNo">
                                                                                                            <button className="btn text-danger btnIconPadd" onClick={minusItemProductCount} id={itd.id}>
                                                                                                                <i className="fa fa-minus" id={itd.id}></i>
                                                                                                            </button>
                                                                                                            <input type="text" name="quantity" className={`form-control qtyNOW50 ${itd.name}`} onBlur={addUpdateCartItem} onFocus={checkItemAddOrUpdate} id={`crtval_${itd.id}`} data-state-id={itd.id} />
                                                                                                            <button className="btn text-primary btnIconPadd" onClick={plusItemProductCount} id={itd.id} >
                                                                                                                <i className="fa fa-plus" id={itd.id}></i>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            ))
                                        }
                                    </div>
                                    <Outlet></Outlet>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="addCartItemModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Product Quantity</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleSubmit} novalidate>
                                        <div className="mb-3 mt-3">
                                            <label for="Product Quntity" className="form-label">Product Quntity :</label>
                                            <input type="text" id="qtyControl" className="form-control" placeholder="Enter Product Quntity" name="Quntity"
                                                value={qtyValue} required pattern="^(([0-9]*)|(([0-9]*)))$"
                                                onChange={(e) => setQtyValue(e.target.value)} />
                                        </div>
                                        <input className="btn btn-primary btn-100" id={PID} type="submit" value="Add" />
                                        <button type="button" className="btn btn-danger btn-100 dp-right" data-bs-dismiss="modal">Close</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" id="orderCartIdChangeQty" value={actCardId} />
                    <ToastContainer limit={1} />
                </div>
            </section>
        </>
    )
}



export default NewOrderDashboard
